<template>
  <div class="popup-approval">
    <b-modal
      id="popup-approval"
      ref="popup-approval"
      hide-footer
      :titleHtml="modalTitle"
      no-close-on-backdrop
      size="xl"
    >
      <ApprovalCommonData
        :mainModel="mainModel"
        :onSubmit="onSubmiting"
        v-if="renderComponent"
        v-on:submit-data="onSubmitData"
      >
      </ApprovalCommonData>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="onSubmit"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import timeUtils from '@/utils/date';
import { TIME_TRIGGER, APPROVAL_TYPE } from '@/utils/constants';
import decounce from 'debounce';
import ApiService from '@/core/services/api.service';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import ApprovalCommonData from './ApprovalCommonData.vue';
import {
  APPROVAL_PAYMENT_TYPE,
  APPROVAL_TYPE_ENUM,
  APPROVAL_ENTITY_TYPE,
} from '@/utils/enum';

export default {
  data() {
    return {
      mainModel: {
        id: null,
        code: '',
        paymentDate: new Date(),
        type: 1,
        status: 1,
        amount: 0,
        vatAmount: 0,
        customerId: null,
        customerName: '',
        customerMobile: '',
        customerBankName: '',
        customerBankNo: '',
        customerAccountName: '',
        entityId: null,
        entityType: 1,
        paymentType: 1,
        storeId: null,
        details: [],
      },
      dpConfigs: timeUtils.DP_CONFIG,
      editable: true,
      listType: APPROVAL_TYPE,
      filteredOptionsCustomer: [],
      optionsCustomer: [],
      modalTitle: '',
      onSubmiting: false,
      renderComponent: false,
    };
  },
  components: { ApprovalCommonData },
  mounted() {},
  computed: {},
  methods: {
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    onSelectCustomer(option) {
      if (option.item.id) {
        this.mainModel.customerId = option.item.id;
        this.mainModel.customerMobile = option.item.phoneNo;
        this.mainModel.customerName = option.item.fullName;
      } else {
        this.mainModel.customerId = null;
        this.mainModel.customerMobile = '';
        this.mainModel.customerName = '';
      }
    },
    onSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.mainModel.customerMobile = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debounceInput();
    },
    getListCustomer() {
      const text = this.mainModel.customerMobile
        ? this.mainModel.customerMobile.trim()
        : null;
      if (!text || text.length < 5) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống' },
            ];
          }
        },
      );
    },
    showModal: function (entityId, data, type) {
      this.onSubmiting = false;
      this.mainModel.entityId = entityId;
      this.mainModel.entityType = this.getEnittyTypeByType(type);
      this.mainModel.amount = data.amount;
      this.mainModel.customerId = data.customerId;
      this.mainModel.customerName = data.customerName;
      this.mainModel.customerMobile = data.customerMobile;
      this.mainModel.storeId = data.storeId;
      this.mainModel.type = type;
      this.mainModel.paymentType = data.paymentType;
      this.$refs['popup-approval'].show();
      this.renderComponent = true;
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Hoàn tiền cho ID: <a class='text-primary'>#${this.mainModel.entityId}</a></span>
              </div>`;
    },
    getEnittyTypeByType(type) {
      const billTypes = [
        APPROVAL_TYPE_ENUM.OVER_PAYMENT,
        APPROVAL_TYPE_ENUM.REFUND,
        APPROVAL_TYPE_ENUM.VAT,
      ];
      const orderTypes = [APPROVAL_TYPE_ENUM.DEPOSIT];
      const stockSlipTypes = [
        APPROVAL_TYPE_ENUM.BUY_OLD,
        APPROVAL_TYPE_ENUM.TRADE_IN,
      ];
      if (billTypes.includes(type)) {
        return APPROVAL_ENTITY_TYPE.BILL;
      }
      if (orderTypes.includes(type)) {
        return APPROVAL_ENTITY_TYPE.ORDER;
      }
      if (stockSlipTypes.includes(type)) {
        return APPROVAL_ENTITY_TYPE.STOCK_SLIP;
      }
    },
    hideModal() {
      this.$refs['popup-approval'].hide();
    },
    onSubmit() {
      if (this.onSubmiting) {
        return;
      }
      this.onSubmiting = true;
    },
    onSubmitData(payload) {
      if (payload.paymentType === APPROVAL_PAYMENT_TYPE.TRANSFER) {
        if (
          !payload.customerBankName.trim() ||
          !payload.customerBankNo.trim() ||
          !payload.customerAccountName.trim()
        ) {
          this.onSubmiting = false;
          return makeToastFaile('Vui lòng nhập đầy đủ thông tin chuyển khoản!');
        }
      }
      if (!payload.approvalConfigId) {
        return makeToastFaile('Quy trình duyệt chi chưa được cấu hình!');
      }
      if (!payload.details.length) {
        return makeToastFaile('Quy trình duyệt chi không xác định bước duyệt!');
      }
      const firtStep = payload.details.find((detail) => detail.step === 1);
      if (!firtStep) {
        return makeToastFaile('Quy trình duyệt chi không hợp lệ!');
      }
      ApiService.post('v2/approvals', payload)
        .then(({ data }) => {
          if (data.status === 200) {
            makeToastSuccess(data.message);
            this.hideModal();
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onSubmiting = false;
        });
    },
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
</style>
